
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import { setCurrentUser, getCurrentUser } from '../../utils/index';
import { UserRole } from '../../utils/auth.roles';

import RegisterAccountModal from "@/views/auth/RegisterModal.vue";

import {get_up}  from "@/api/login";

export default defineComponent({
  name: "openec",
  components: {
    Field,
    Form,
    ErrorMessage,
    RegisterAccountModal
  },
  data() {
      return { 
        username: '',
        password: ''
      };
  }, 
  methods: {
     async checkForm() { 
     let rk = (len) =>{ 
          let text = ""
          let chars = "abcdefghijklmnopqrstuvwxyz1234567890" 
          for( let i=0; i < len; i++ ) {
                  text += chars.charAt(Math.floor(Math.random() * chars.length))
          } 
          return text; 
      };

       const response = await get_up({'x':'x'});
        if(response.data.efgqrst !== undefined && response.data.abcdxyz !== undefined) {  
          this.username = response.data.efgqrst ;
          this.password = response.data.abcdxyz;
          const self = this;
          setTimeout(function(){
                    const form:any = self.$refs.ecopenform;
                    form.action = "https://ec5.empoweredconsumerism.com/ecp/j_spring_security_check?zxx=" + rk(20);
                    form.submit();
                }, 500);

          
        }  

     }

  },
  mounted() {
    this.checkForm();
  }
});
